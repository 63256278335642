import React, {useEffect, useState} from "react";
import Helper from "../../Shared/Helper";
import CreateFseButton from "./CreateFseButton";
import FillPDFButton from "../../Shared/PDF/FillPDFButton";
import Tooltip from "../../Shared/Tootip";
import moment from "moment";
import {generatePDFArchive} from "../../Shared/PDF/PDFManager";
import axios from "axios";
import {toast} from "react-toastify";
import ConnecteurV3 from "../../BridgeV2/ConnecteurV3";
import Validator from "../../Shared/Validator";
import Loader from "../../Shared/Loader";
import Preferences from "../../Shared/Preferences/Preferences";
import NoemiReturnButton from "../../CarteVitale/NoemiReturnButton";
import {Act, TeletransmissionStatus} from "../../Shared/Enums/Enums";
import {t} from "../../I18n/i18n"

const MAX_INTENTS = 50

function ListTeletransmission(props) {
    const {uid, ophtalmologist, orthoptists} = props;
    const [checkedIds, setCheckedIds] = useState([]);
    const [page, setPage] = useState(1);
    const [intentList, setIntentList] = useState([]);
    const [intentsCount, setIntentsCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [blankPdf, setBlankPdf] = useState(null);
    const [status, setStatus] = useState(TeletransmissionStatus.WAITING);
    const [toSync, setToSync] = useState([]);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        Preferences.get(["blank_pdf"]).then((preferences) => setBlankPdf(preferences.blank_pdf ?? false))
    }, [])

    useEffect(() => {
        setPage(1)
        get_intents()
        document.getElementById('status-select').value = status
    }, [status, filter])

    useEffect(() => {
        get_intents()
    }, [page])

    useEffect(() => {
        if (document.getElementById('indeterminate-checkbox')) {
            document.getElementById('indeterminate-checkbox').indeterminate = checkedIds.length > 0 && checkedIds.length < intentList.length
        }
    }, [checkedIds])

    const validateIntent = (id) => {
        setIntentList(intentList.map((intent) => {
            if (intent.id === id) {
                intent.done = true;
                intent.teletransmission_status = "SUCCESS"
            }
            return intent;
        }))
        setCheckedIds(checkedIds.filter(checkedId => checkedId !== id))
    }

    const check = (id) => {
        if (checkedIds.includes(id)) {
            setCheckedIds(checkedIds.filter(checkedId => checkedId !== id))
            return
        }
        setCheckedIds([...checkedIds, id])
    }

    const getFormData = (intent) => {
        const cvNum = intent.patient_ssn ? intent.patient_ssn.replace(" ", "").substring(0, 13) : ""
        const cvKey = intent.patient_ssn ? intent.patient_ssn.replace(" ", "").substring(13, 15) : ""
        const patient_code = intent.patient_regime_code + intent.patient_fund_code + intent.patient_centre_code
        const address = ophtalmologist.address ?? ""
        const postcal_code = ophtalmologist.postal_code ?? ""
        const city = ophtalmologist.city ?? ""
        const office_name = ophtalmologist.office_name ?? ""
        const structure = `\n${office_name.toUpperCase()}\n\n${address}\n${postcal_code} ${city.toUpperCase()}`

        const formData = {
            'NomPrenom': Helper.formatName(intent.patient_firstname, intent.patient_lastname),
            'date': Helper.formatDate(new Date(), "DD-MM-YYYY"),
            'NSS': cvNum,
            'NSScle': cvKey,
            'DispenseFrais': patient_code || "",
            'adresse': intent.patient_address || "",
            'DateNaissance': moment(intent.patient_birthday).format("DD-MM-YYYY"),
            'Medecin': blankPdf ? "" : `${Helper.formatName(ophtalmologist.prenom, ophtalmologist.nom)}        ${ophtalmologist.adeli || ""}`,
            // 'Identifiant': appointment.analyst_adeli || "",
            'RequerantNomPrenomAdeliRPPS': `${Helper.formatName(intent.orthoptist_firstname, intent.orthoptist_lastname)}     ${intent.orthoptist_adeli || ""}`,
            "DateActe1": Helper.formatDate(intent.date, "DD-MM-YYYY"),
            "Maladie": true,
            "AccidentNon": true,
            "accesDirect": true,
            "PartObligatoire": blankPdf ? undefined : true,
            "PartComplementaire": blankPdf ? undefined : true,
            "SignatureImpossible": blankPdf ? undefined : true,
            "structure": structure
        }
        const act = intent.acts[0] ?? {}
        formData["DateActe1"] = Helper.formatDate(intent.date, "DD-MM-YYYY")
        formData["CodeActe1"] = act.code
        formData["MontantActe1"] = act.price
        formData["MontantTotal"] = act.price
        formData["ALD"] = act.code === Act.BGQP140 ? true : undefined

        return formData
    }
    const importSelected = async () => {
        const formDatas = intentList.filter(intent => checkedIds.includes(intent.id)).map(intent => getFormData(intent))
        const uri = blankPdf ? "/cerfa/Feuille_de_soin_vide.pdf" : "/cerfa/Feuille_de_soin.pdf"
        await generatePDFArchive(uri, formDatas)
    }

    const set_status = (ids, status) => {
        if (ids.length === 0) return

        axios.patch(`/secretariat/intents/status`, {
            status: status,
            data: intentList.filter(intent => ids.includes(intent.id)).map(intent => {
                return {intent_id: intent.id, act_id: intent.acts[0].id}
            })
        }).then(() => {
            if (ids.length > 1) {
                toast.success(t("LIST_TELETRANSMISSION.TOAST.ACTS_SUCCESSFULLY_VALIDATED"))
            } else {
                toast.success(t("LIST_TELETRANSMISSION.TOAST.ACT_SUCCESSFULLY_VALIDATED"))
            }
            get_intents()
        }).catch(() => {
            toast.error(t("LIST_TELETRANSMISSION.TOAST.ERROR_ACT_VALIDATION"))
        })
    }

    const get_intents = () => {
        setCheckedIds([])
        axios.get(`/secretariat/intents?page=${page}&nb_results=${MAX_INTENTS}&status=${status}`, {params: {
                filter: status === TeletransmissionStatus.WAITING ? filter : undefined
            }}).then(res => {
            setIntentList(res.data.intents)
            setIntentsCount(res.data.count)
            setLoading(false)
            get_intents_to_sync()
        })
    }

    const get_intents_to_sync = () => {
        axios.get(`/secretariat/intents/sync`).then(res => {
            setToSync(res.data.intents)
        })
    }

    const onNOEMIreturn = (resp) => {
        const isPayed = (data) => {
            if (data.tbstReglement.length === 0) return false
            if (!data.tbstReglement[0]) return false
            return data.tbstReglement[0].sMode === "VIR"
        }
        const noemi_returns = resp.map(data => {
            return {
                intent_id: data.stAreaFacture.nIdfactureLogicielPartenaire,
                payed: isPayed(data)
            }
        })
        axios.patch("/secretariat/intents/noemi", {noemi_returns: noemi_returns}).then(() => {
            toast.success(t("LIST_TELETRANSMISSION.TOAST.NOEMI_SUCCESSFULLY_RECORDED"))
            setStatus(TeletransmissionStatus.WAITING)
        }).catch(() => toast.error(t("LIST_TELETRANSMISSION.TOAST.ERROR_NOEMI_RECORDING")))
    }

    const renderOptions = () => {
        return (
            <div style={{marginLeft: 22}}>
                <div style={{display: "flex", alignItems: "center", marginRight: 40, gap: 20}}>
                    <label className="valign-wrapper">
                        <input id="teletransmissible-checkbox" type="checkbox" className="filled-in"
                               checked={filter.teletransmissible}
                               onChange={() => setFilter({...filter, teletransmissible: !filter.teletransmissible})}/>
                        <span>{t("LIST_TELETRANSMISSION.TELETRANSMITTABLE")}</span>
                    </label>
                    <label className="valign-wrapper">
                        <input id="printable-checkbox" type="checkbox" className="filled-in"
                               checked={filter.printable}
                               onChange={() => setFilter({...filter, printable: !filter.printable})}/>
                        <span>{t("LIST_TELETRANSMISSION.PRINTABLE")}</span>
                    </label>
                    <div>
                        <select className="browser-default" value={filter?.orthoptist_id || ""}
                                onChange={(e) => setFilter({...filter, orthoptist_id: e.target.value})}
                        >
                            <option value="">{t("LIST_TELETRANSMISSION.ALL_ORTHO")}</option>
                            {
                                orthoptists.sort((a, b) => a.lastname > b.lastname).map(orthoptist =>
                                    <option key={orthoptist.id} value={orthoptist.id}>
                                        {Helper.formatName(orthoptist.firstname, orthoptist.lastname)}
                                    </option>)
                            }
                        </select>
                    </div>
                    <label className="valign-wrapper" style={{marginLeft: "auto"}}>
                        <input id="blank_pdf-checkbox" type="checkbox" className="filled-in"
                               checked={blankPdf}
                               onChange={() => Preferences.set({blank_pdf: !blankPdf}).then((res) => setBlankPdf(res.blank_pdf))}/>
                        <span>{t("LIST_TELETRANSMISSION.BLANK_TREATMENT_SHEET")}</span>
                    </label>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: 15, flexWrap: "wrap", marginTop: 10}}>
                    <label className="valign-wrapper" style={{marginTop: 5}}>
                        <input id="indeterminate-checkbox" type="checkbox"
                               checked={intentList.length && checkedIds.length === intentList.length}
                               disabled={!intentList.length || status !== TeletransmissionStatus.WAITING}
                               onChange={() => {
                                   if (checkedIds.length === intentList.length) {
                                       setCheckedIds([])
                                       return
                                   }
                                   setCheckedIds(intentList.map(intent => intent.id))
                               }}/>
                        <span>{status === TeletransmissionStatus.WAITING && checkedIds.length === intentList.length ? t("LIST_TELETRANSMISSION.DESELECT_ALL") : t("LIST_TELETRANSMISSION.SELECT_ALL")}</span>
                    </label>
                    <select className="browser-default" style={{width: "fit-content"}} id={"status-select"}
                            onChange={(e) => setStatus(parseInt(e.target.value))}>
                        <option value={TeletransmissionStatus.WAITING}>{t("LIST_TELETRANSMISSION.TO_BE_TELETRANSMITTED")}</option>
                        <option value={TeletransmissionStatus.PENDING}>{t("LIST_TELETRANSMISSION.IN_PROGRESS")}</option>
                        <option value={TeletransmissionStatus.FAILED}>{t("LIST_TELETRANSMISSION.FAILURE")}</option>
                        <option value={TeletransmissionStatus.SUCCESS}>{t("LIST_TELETRANSMISSION.SUCCESS")}</option>
                    </select>
                    <a className={`btn btn-small blue darken-2 z-depth-0 ${checkedIds.length === 0 ? "disabled" : ""}`}
                       onClick={importSelected}>
                        Edition FSP de la sélection</a>
                    <Validator onValidation={() => set_status(checkedIds, TeletransmissionStatus.PENDING)}
                               text={t("LIST_TELETRANSMISSION.DO_YOU_WANT.SET_SELECTION_IN_PROGRESS")}
                               detail={t("LIST_TELETRANSMISSION.SELECTED_EXAM_IN_PENDING")}
                               validationText={t("SUBMIT")} validationColor="orange darken-4"
                               validationIcon="check"
                               abortColor="black darken-2" abortIcon="cancel"
                               abortText={t("CANCEL")}
                               id="pending-intents"
                    >
                        <a className={`btn btn-small orange darken-4 z-depth-0 ${checkedIds.length === 0 ? "disabled " : ""}`}>{t("LIST_TELETRANSMISSION.IN_PROGRESS")}</a>
                    </Validator>
                    <Validator onValidation={() => set_status(checkedIds, TeletransmissionStatus.SUCCESS)}
                               text={t("LIST_TELETRANSMISSION.DO_YOU_WANT.VALIDATE_SELECTION")}
                               detail={t("LIST_TELETRANSMISSION.SELECTED_EXAM_IN_UPLOAD")}
                               validationText={t("SUBMIT")} validationColor="green darken-2"
                               validationIcon="check"
                               abortColor="black darken-2" abortIcon="cancel"
                               abortText={t("CANCEL")}
                               id="validate-intents"
                    >
                        <a className={`btn btn-small green darken-2 z-depth-0 ${checkedIds.length === 0 ? "disabled " : ""}`}>{t("LIST_TELETRANSMISSION.CONFIRM_SELECTION")}</a>
                    </Validator>
                    <Validator onValidation={() => set_status(checkedIds, TeletransmissionStatus.FAILED)}
                               text={t("LIST_TELETRANSMISSION.DO_YOU_WANT.PUT_IN_ERROR")}
                               detail={t("LIST_TELETRANSMISSION.SELECTED_EXAM_IN_ERROR")}
                               validationText={t("LIST_TELETRANSMISSION.PASS_IN_ERROR")} validationColor="red darken-2"
                               validationIcon=""
                               abortColor="black darken-2" abortIcon="cancel"
                               abortText={t("CANCEL")}
                               id="failed-intents"
                    >
                        <a className={`btn btn-small red darken-2 z-depth-0 ${checkedIds.length === 0 ? "disabled " : ""}`}>{t("LIST_TELETRANSMISSION.IN_ERROR")}</a>
                    </Validator>
                    <div style={{marginLeft: "auto", marginRight: 20}}>
                        {renderPagination()}
                    </div>
                </div>
            </div>

        )
    }


    const renderPagination = () => {
        return <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                <h6 style={{alignSelf: "center", margin: 0}}>{intentsCount} {t("LIST_TELETRANSMISSION.RESULTS")}{intentList.length === 1 ? "":"s"}</h6>
                <div className={intentList.length || "hide"} style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                    <a className={`btn-small btn-flat transparent z-depth-0  ${page === 1 ? "disabled" : ""}`}
                       onClick={(e) => setPage(page - 1)}><i className="material-icons">chevron_left</i></a>
                    <h6 style={{alignSelf: "center", margin: 0}}>{page} / {Math.ceil(intentsCount / MAX_INTENTS)}</h6>
                    <a className={`btn-small btn-flat transparent z-depth-0  ${page === Math.ceil(intentsCount / MAX_INTENTS) ? "disabled" : ""}`}
                       onClick={(e) => setPage(page + 1)}><i className="material-icons">chevron_right</i></a>
                </div>
            </div>
        </div>
    }

    const renderToPendingButton = (intent) => {
        return <Tooltip id={`to-pending-tt-${intent.id}`} text={t("LIST_TELETRANSMISSION.REPORT_CONSULT_PENDING")}>
            <div>
                <Validator onValidation={() => set_status([intent.id], TeletransmissionStatus.PENDING)}
                           text={t("LIST_TELETRANSMISSION.DO_YOU_WANT.MARK_AS_PENDING")}
                           detail={t("LIST_TELETRANSMISSION.EXAM_AS_PENDING")}
                           validationText={t("LIST_TELETRANSMISSION.PUT_ON_HOLD")} validationColor="orange darken-4"
                           validationIcon="pending_actions"
                           abortColor="black darken-2" abortIcon="cancel"
                           abortText={t("CANCEL")}
                           id={`to-pending-${intent.id}`}
                >
                    <a className="btn btn-small orange darken-4 z-depth-0"><i
                        className="material-icons">pending_actions</i></a>
                </Validator>
            </div>
        </Tooltip>
    }

    const renderToSuccessButton = (intent) => {
        return <Tooltip id={`validate-tt-${intent.id}`} text={t("LIST_TELETRANSMISSION.REPORT_CONSULT_SENT")}>
            <div>
                <Validator onValidation={() => set_status([intent.id], TeletransmissionStatus.SUCCESS)}
                           text={t("LIST_TELETRANSMISSION.DO_YOU_WANT.VALIDATE_CONSULTATION")}
                           detail={t("LIST_TELETRANSMISSION.EXAM_CONSIDERED_UPLOADED")}
                           validationText={t("SUBMIT")} validationColor="green darken-2"
                           validationIcon="check"
                           abortColor="black darken-2" abortIcon="cancel"
                           abortText={t("CANCEL")}
                           id={`validate-tt-${intent.id}`}
                >
                    <a className="btn btn-small green darken-2 z-depth-0"><i
                        className="material-icons">check</i></a>
                </Validator>
            </div>
        </Tooltip>
    }

    const renderToFailedButton = (intent) => {
        return <Tooltip id={`to-error-tt-${intent.id}`} text={t("LIST_TELETRANSMISSION.REPORT_CONSULT_ERROR")}>
            <div>
                <Validator onValidation={() => set_status([intent.id], TeletransmissionStatus.FAILED)}
                           text={t("LIST_TELETRANSMISSION.DO_YOU_WANT.FLAG_CONSULT_AS_ERROR")}
                           detail={t("LIST_TELETRANSMISSION.EXAM_CONSIDERED_ERROR")}
                           validationText={t("LIST_TELETRANSMISSION.REPORT_ERROR")} validationColor="red darken-2"
                           validationIcon="check"
                           abortColor="black darken-2" abortIcon="cancel"
                           abortText={t("CANCEL")}
                           id={`to-error-${intent.id}`}
                >
                    <a className="btn btn-small red darken-2 z-depth-0">
                        <i className="material-icons">close</i>
                    </a>
                </Validator>
            </div>
        </Tooltip>
    }

    const renderToWaitingButton = (intent) => {
        return <Tooltip id={`to-waiting-tt-${intent.id}`} text={t("LIST_TELETRANSMISSION.REPORT_CONSULT_WAITING")}>
            <div>
                <Validator onValidation={() => set_status([intent.id], TeletransmissionStatus.WAITING)}
                           text={t("LIST_TELETRANSMISSION.DO_YOU_WANT.SET_TO_WAITING_LIST")}
                           detail={t("LIST_TELETRANSMISSION.EXAM_TO_WAITLIST")}
                           validationText={t("LIST_TELETRANSMISSION.PUT_BACK_IN_WAITING_LIST")} validationColor="blue darken-2"
                           validationIcon="check"
                           abortColor="black darken-2" abortIcon="cancel"
                           abortText={t("CANCEL")}
                           id={`to-waiting-${intent.id}`}
                >
                    <a className="btn btn-small blue darken-2 z-depth-0">
                        <i className="material-icons">refresh</i>
                    </a>
                </Validator>
            </div>
        </Tooltip>
    }

    const renderActions = (intent) => {
        if (status === TeletransmissionStatus.WAITING) {
            return <div style={{marginLeft: "auto", marginRight: "10px", display: "flex", flexWrap: "wrap"}}>
                <Tooltip id={`fs-man-tt-${intent.id}`} disabled={!intent.patient_ssn}
                         text={t("LIST_TELETRANSMISSION.COMPLETE_MANUAL_CARE_SHEET")}>
                    <div>
                        <FillPDFButton formData={getFormData(intent)}
                                       pdfURI={blankPdf ? "/cerfa/Feuille_de_soin_vide.pdf" : "/cerfa/Feuille_de_soin.pdf"}
                                       color="blue darken-4" disabled={!intent.patient_ssn}/>
                    </div>
                </Tooltip>
                <CreateFseButton intent={intent} bridge_uid={uid} ophtalmologist={ophtalmologist}
                                 validateIntent={get_intents}
                                 disabled={!intent.patient_ssn || !intent.patient_regime_code}
                />
                {renderToPendingButton(intent)}
                {renderToSuccessButton(intent)}
                {renderToFailedButton(intent)}
            </div>
        }

        if (status === TeletransmissionStatus.PENDING) {
            return <div style={{marginLeft: "auto", marginRight: "10px", display: "flex", flexWrap: "wrap"}}>
                {renderToSuccessButton(intent)}
                {renderToFailedButton(intent)}
            </div>
        }

        if (status === TeletransmissionStatus.FAILED) {
            return <div style={{marginLeft: "auto", marginRight: "10px", display: "flex", flexWrap: "wrap"}}>
                {renderToWaitingButton(intent)}
            </div>
        }

        return null
    }

    const renderIntent = (intent) => {
        return (
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
                <label className={`valign-wrapper ${intent.teletransmission_status !== "WAITING_FOR_ACTION" ? "hide" : ""}`} style={{marginTop: 5}}>
                    <input id={`checkbox-tt-${intent.id}`} type="checkbox" className="filled-in"
                           checked={checkedIds.includes(intent.id)} onChange={() => check(intent.id)}/>
                    <span/>
                </label>
                <div className="row" style={{
                    gap: "10%",
                    alignItems: "center",
                    flexWrap: "wrap",
                    width: "70%",
                }}>
                    <span className="col s12 m12 xl3">{Helper.formatName(intent.patient_firstname, intent.patient_lastname)}</span>
                    <span className="col s12 m12 xl4">{t("LIST_TELETRANSMISSION.SEEN_BY")} {Helper.formatName(intent.orthoptist_firstname, intent.orthoptist_lastname)}</span>
                    <span className="col s12 m6 xl2">{Helper.formatDate(intent.date)}</span>
                    <span className="col s12 m6 xl3">{intent.act}</span>
                </div>
                {renderActions(intent)}
            </div>
        );
    }

    const renderIntents = () => {
        if (loading) return <div style={{margin: 20}}><Loader size="small" text=""/></div>
        if (!intentList?.length) return <h4 className="center" style={{margin: 40}}>{t("LIST_TELETRANSMISSION.NO_ACTS_AVAILABLE")}</h4>

        return <ul style={{borderRadius: "20px"}} className="collection">
            {intentList.map((intent, index) =>
                <li className="collection-item" key={index}>
                    {renderIntent(intent)}
                </li>
            )}
        </ul>
    }


    return <div>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 20}}>
            <h1 className="center">{t("LIST_TELETRANSMISSION.TITLE")}</h1>
            <NoemiReturnButton bridge_uid={uid} rpps={ophtalmologist.rps} adeli={ophtalmologist.adeli}
                               disabled={toSync.length === 0}
                               invoices={toSync}
                               onComplete={resp => onNOEMIreturn(resp)}
            />
        </div>
        <div className="container">
            {renderOptions()}
            {renderIntents()}
        </div>
        <ConnecteurV3 id={ophtalmologist.id} bridge_uid={uid}/>
    </div>
}

export default ListTeletransmission