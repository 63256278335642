import React, {useEffect, useState} from "react";
import {RequestState} from "../Shared/StateHelper";
import Loader from "../Shared/Loader";
import Error from "../Shared/Error";
import axios from "axios";
import AddPrescriptorButton from "./AddPrescriptorButton";
import Helper from "../Shared/Helper";
import {Act} from "../Shared/Enums/Enums";
import CVStore from "../CarteVitale/CVStore";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {toast} from "react-toastify";
import MesureStore from "../Appointments/Mesures/MesureStore";
import {observer} from "mobx-react";

const SelectPrescriptor = observer(({onSelect}) => {

    const updatePrescriptionDate = (date) => {
        if(MesureStore.selectedChain?.parent_appointment_id) {
            CVStore.prescriptionDate = Helper.formatDate(date, "YYYY-MM-DD")
            return
        }

        return axios.put("/prescripteurs/prescription_date", {
            appointment_id: MesureStore.appointement_id,
            prescription_date: date
        }).then(res => {
            CVStore.prescriptionDate = Helper.formatDate(res.data.prescription_date, "YYYY-MM-DD")
        })
    }

    const handlePrescriptorChange = () => {
        if(MesureStore.selectedChain?.parent_appointment_id) return

        axios.post(`/prescripteurs/updatePrescriptor`, {
            prescriptor_id: CVStore.selectedPrescriptor?.id,
            appointment_id: MesureStore.appointement_id,
        }).then(res => {
            // if (MesureStore.selected_act.titre !== Act.RNM) {
            //     toast.success("Le prescripteur est désormais associé à cette consultation.")
            // }
        }).catch(err => {
            toast.error("Une erreur s'est produite lors de la selection du prescripteur.")
        })
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
        <div className="input-field outlined" style={{gap: 20}}>
            <input type="date" style={{borderRadius: 4}}
                   value={CVStore.prescriptionDate}
                   onChange={e => updatePrescriptionDate(e.target.value)}
            />
            <label>Date de la prescription</label>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <Autocomplete
                className="ui-autocomplete"
                value={CVStore.selectedPrescriptor}
                options={CVStore.prescriptors}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => Helper.formatName(option.firstname, option.lastname)}
                filterSelectedOptions
                onChange={(_, prescriptor) => {
                    if (prescriptor === null) {
                        CVStore.selectedPrescriptor = null
                        handlePrescriptorChange();
                    } else {
                        CVStore.selectedPrescriptor = prescriptor
                        handlePrescriptorChange(prescriptor.id)
                    }
                }}
                sx={{width: "100%"}}
                renderInput={(params) => <TextField {...params} label={"Sélectionner un prescripteur"}/>}
            />
            <AddPrescriptorButton
                onSave={(prescriptor) => {
                CVStore.getPrescriptors().then(() => onSelect(prescriptor))
            }}/>
        </div>
    </div>
})

export default SelectPrescriptor;